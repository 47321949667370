@each $prop, $abbrev in (width: w) {
    @each $size, $length in $sizes {
        .m#{$abbrev}-#{$size} { max-#{$prop}: $length; }
    }
    @if $enable-responsive-mw-classes == true {
        @each $breakpoint in map-keys($grid-breakpoints) {
            @if $breakpoint != xs {
                @each $size, $length in $sizes {
                    
                    @include media-breakpoint-up($breakpoint) {
                        .m#{$abbrev}-#{$breakpoint}-#{$size} { 
                            max-#{$prop}: $length ;
                        } 
                    }
                }
            }
        }
    }
} 