/* ONLY Coveo styles that are relevant to the search bar in the nav. All Coveo styles, including search results page, are in the search stylesheet */

.CoveoSearchInterface {
    min-height: 100%;
    position: relative;
    margin: 0;
    font-size: 15px;
}

// .CoveoSearchInterface {
//     min-width: 300px;
// }

.coveo-after-initialization > * {
    display: inherit;
    visibility: inherit;
}

.CoveoSearchInterface * {
    box-sizing: border-box;
}

.coveo-search-section {
    display: flex;
}

.CoveoSearchInterface .coveo-search-section {
    //max-width: 800px;
    margin: 54px auto 47px;
    padding: 0;
}

.CoveoSearchInterface .coveo-search-section {
    margin-top: 0;
}

.CoveoSearchInterface .coveo-search-section {
    margin-bottom: 0;
}

.CoveoSearchbox {
    display: flex;
}

.coveo-search-section .CoveoSearchbox {
    -ms-flex: 1;
    flex: 1;
    //margin-right: 10px;
}

.magic-box {
    position: relative;
    text-align: left;
    color: $secondary;
}

.CoveoSearchbox .magic-box {
    flex-grow: 1;
    border: thin solid #bcc3ca;
    border-radius: 2px;
}

.magic-box .magic-box-input {
    background: #fff;
    height: 48px;
    overflow: hidden;
    position: relative;
}

.CoveoSearchbox .magic-box .magic-box-input {
    border-radius: 2px;
}

.magic-box .magic-box-input .magic-box-underlay, .magic-box .magic-box-input > input {
    color: $secondary;
    text-align: left;
    padding: 12px 49px 12px 0;
    text-indent: 12px;
    font-size: 20px;
    line-height: 24px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 48px;
    white-space: pre;
    overflow: auto;
    box-sizing: border-box;
    display: block;
}

.magic-box .magic-box-input .magic-box-underlay {
    color: transparent;
    overflow: hidden;
}

.magic-box .magic-box-input .magic-box-underlay > span {
    text-indent: 0;
}

.magic-box .magic-box-input .magic-box-underlay span {
    display: inline-block;
    vertical-align: top;
}

.coveo-query-syntax-disabled.magic-box .magic-box-input .magic-box-underlay span, .coveo-query-syntax-disabled .magic-box-highlight-container {
    display: none;
}

.magic-box .magic-box-input .magic-box-underlay span[data-value=''] {
    height: 24px;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text {
    visibility: hidden;
    color: #bdbdbd;
}

.magic-box .magic-box-input .magic-box-underlay .magic-box-ghost-text, .magic-box.magic-box-hasFocus .magic-box-input .magic-box-underlay .magic-box-ghost-text {
    display: none;
}

.magic-box.magic-box-hasFocus .magic-box-suggestions.magic-box-hasSuggestion {
    display: block;
  }

.magic-box .magic-box-input input {
    background: none;
    border: none;
    resize: none;
    outline: 0;
}

.magic-box .magic-box-clear {
    position: relative;
    float: right;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-size: 24px;
    transition: width 0.3s;
    cursor: pointer;
    background: #fff;
    width: 0;
    overflow: hidden;
}

.CoveoSearchbox .magic-box .magic-box-clear-svg {
    width: 15px;
    height: 15px;
    color: $primary;
}

.magic-box .magic-box-suggestions {
    clear: both;
    position: absolute;
    top: 100%;
    left: -1px;
    right: -1px;
    overflow: hidden;
    background: #fff;
    z-index: 1;
    display: none;
    border: 1px solid #9e9e9e;
}

.CoveoOmnibox.magic-box .magic-box-suggestions {
    z-index: 3;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions .magic-box-suggestion {
  opacity: 1;
  transition: opacity 0.3s;
}

.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion-seperator,
.magic-box .magic-box-suggestions.magic-box-suggestions-loading .magic-box-suggestion {
  opacity: 0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator {
  padding: 12px 12px 0;
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
  height: 36px;
  border-top: 1px solid #e0e0e0;
  color: #9e9e9e;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator .magic-box-suggestion-seperator-label {
  float: left;
  margin-right: 12px;
  max-width: 75%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator:after {
  content: '';
  display: block;
  position: relative;
  top: 50%;
  height: 1px;
  overflow: hidden;
  background-color: #e0e0e0;
}

.magic-box .magic-box-suggestions .magic-box-suggestion-seperator + .magic-box-suggestion {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion {
  cursor: pointer;
  line-height: 24px;
  padding: 12px;
  font-size: 16px;
  background: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-top: 1px solid #fafafa;
  color: $secondary;
}

.magic-box .magic-box-suggestions .magic-box-suggestion .magic-box-hightlight {
  font-weight: bold;
  color: #000;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:first-child {
  border-top: none;
}

.magic-box .magic-box-suggestions .magic-box-suggestion.magic-box-selected {
  background: #e0f7fa !important;
}

.magic-box .magic-box-suggestions .magic-box-suggestion:hover {
  background: #f5f5f5;
}

.magic-box.magic-box-inline {
  overflow: hidden;
}

.magic-box.magic-box-inline .magic-box-suggestions.magic-box-hasSuggestion {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  border: none;
  border-top: 1px solid #9e9e9e;
}

// .CoveoSearchButton {
//     color: white;
//     border: 1px solid #bcc3ca;
//     border-left: none;
//     text-decoration: none;
//     text-align: center;
//     vertical-align: middle;
//     overflow: hidden;
//     // height: 50px;
//     // width: 60px;
//     cursor: pointer;
//     line-height: 0;
// }

.coveo-after-initialization meter, .coveo-after-initialization progress, .coveo-after-initialization input, .coveo-after-initialization textarea, .coveo-after-initialization keygen, .coveo-after-initialization select, .coveo-after-initialization button {
    display: inline-block;
}

// .CoveoSearchButton:before {
//     content: '';
//     vertical-align: middle;
//     height: 100%;
//     display: inline-block;
// }

.coveo-visible-to-screen-reader-only {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

/* OVERRIDES */

.coveo-search-section {
    display: flex;
    padding: 0;
}

// .CoveoSearchInterface .coveo-search-section {
//   max-width: 300px;
// }

// .CoveoSearchInterface .coveo-results-column {
//   max-width: 800px;
// }

.coveo-search-section .CoveoSearchbox {
  flex: 1;
  //margin-right: 10px;
}

.coveo-search-section .CoveoSearchButton {
//   margin-left: 10px;
//   margin-right: 10px;
  border: none;
//   background-color: #eee;
//   color: #666;
}

/* Search in the mobile nav */ 
.global-nav { 
    @media screen and (max-width: $mq-desktop-sm) {
        .coveo-search-section {
            flex-direction: column;
            margin: 0 auto;
            padding: 0;
        }
        .CoveoSearchButton {
            width: 100%;
            margin: 10px 0 0 0;
        }
        .CoveoSearchbox {
            margin-right: 0;
        }
        .CoveoSearchInterface {
            min-width: 0;
        }
    }
}


.input-group{
    .magic-box{
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
    }
    .btn{
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
}

#CoveoNavSearch,
#CoveoMobileNavSearch{
    input{
        background-color: $gray-200;
        border: 0;
        font-family: $accentFont;
        font-weight: 700;
    }
    .coveo-search-button{
        display: none;
    }
    .coveo-search-button-loading{
        display: none;
    }
    .magic-box{
        border: 0;
    }
    .btn{
        border: 0;
    }
}


.magic-box .magic-box-input {
    height: 36px;
}

.magic-box .magic-box-input .magic-box-underlay, .magic-box .magic-box-input > input {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 36px;
}

// .CoveoSearchButton {
//     //height: 36px;
//     // margin-top: 0;
//     // margin-bottom: 0;
//     // padding: 0;
// }

.magic-box .magic-box-clear {
    line-height: 36px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    //color: red;
    opacity: 1; /* Firefox */
    font-family: $accentFont;
    font-weight: 700;
    color: $gray-500!important;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
    //color: red;
    font-family: $accentFont;
    font-weight: 700;
    color: $gray-500!important;
}

::-ms-input-placeholder { /* Microsoft Edge */
    //color: red;
    font-family: $accentFont;
    font-weight: 700;
    color: $gray-500!important;
}