ul{
	&.list-icons{
		//border: 1px solid red;
		list-style-type: none;
		padding-left: 0;
		li {
			//border: 1px solid blue;
			margin: 0 0 .75rem 1.5rem;
			.svg-inline--fa{
				margin-left: -1.5rem;
				color: $tertiary;
			}
		}
	}
}

h1,h2{
	text-transform: uppercase;
}