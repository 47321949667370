
.btn-primary,
.btn-secondary,
.btn-tertiary,
.btn-quaternatry,
.btn-quinary,
.btn-senary
{
	font-family: $accentFont;
	font-weight: 700;
}

.btn-link
{
	font-weight: 600;
}


.btn-icon{
	display: inline-flex;
	align-items: center;
	white-space: nowrap!important;
	span{
		white-space: nowrap!important;
	}
	svg{
		font-size: 1rem;
	}
}

.btn-icon{
	&-right{
		@extend .btn-icon;
		.svg-inline--fa{
			margin-left: .75rem;
		}
	}
	&-left{
		@extend .btn-icon;
		.svg-inline--fa{
			margin-right: .75rem;
		}
	}
}