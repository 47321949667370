///*-------------------------------------------------------------------------- 
//    FONTS
//--------------------------------------------------------------------------*/




@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim Thin'), local('GroldRoundedSlim-Thin'),
        url('/fonts/groldrounded/groldroundedslim-thin-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-thin-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}



@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim Light'), local('GroldRoundedSlim-Light'),
        url('/fonts/groldrounded/groldroundedslim-light-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-light-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-light.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
}



//body copy
@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim SemiLight'), local('GroldRoundedSlim-SemiLight'),
        url('/fonts/groldrounded/groldroundedslim-semilight-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-semilight-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-semilight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim'), local('GroldRoundedSlim-Regular'),
        url('/fonts/groldrounded/groldroundedslim-regular-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-regular-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

//subheads
@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim Medium'), local('GroldRoundedSlim-Medium'),
        url('/fonts/groldrounded/groldroundedslim-medium-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-medium-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
}


//missing 600/semi-bold
// @font-face {
//     font-family: 'Grold Rounded Slim';
//     src: local('Grold Rounded Slim semibold'), local('GroldRoundedSlim-semibold'),
//         url('/fonts/groldrounded/groldroundedslim-semibold.woff2') format('woff2'),
//         url('/fonts/groldrounded/groldroundedslim-semibold.woff') format('woff'),
//         url('/fonts/groldrounded/groldroundedslim-semibold.otf') format('opentype');
//     font-weight: bold;
//     font-style: normal;
// }


@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim Bold'), local('GroldRoundedSlim-Bold'),
        url('/fonts/groldrounded/groldroundedslim-bold-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-bold-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-bold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}


//headlines
@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim ExtraBold'), local('GroldRoundedSlim-ExtraBold'),
        url('/fonts/groldrounded/groldroundedslim-extrabold-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-extrabold-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
}


@font-face {
    font-family: 'Grold Rounded Slim';
    src: local('Grold Rounded Slim Black'), local('GroldRoundedSlim-Black'),
        url('/fonts/groldrounded/groldroundedslim-black-webfont.woff2') format('woff2'),
        url('/fonts/groldrounded/groldroundedslim-black-webfont.woff') format('woff'),
        url('/fonts/groldrounded/groldroundedslim-black.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}
