.content-table{
	//color:$midNeutral; 
    font-size:0.9em; 
    //width:98%;
}
.content-table__row {border-bottom:1px solid $body-color;}
.content-table__column {padding:0.35em;}
.content-table__foot-note {
   // color:$midNeutral;
    font-size:0.7em; }
.table__left {border-left:1px solid $body-color;}



//todo-jk: if no mileage tables, then dump this ^^^^^^^^