
$imageGridSpacer: .25rem;

.image-grid{
	display: flex;
	flex-wrap: wrap;

	.image-grid-item{
		position: relative;
		//border: 1px solid red;
		width: calc(50% - $imageGridSpacer);
		min-height: 200px;
		margin: 0 ($imageGridSpacer * .5) $imageGridSpacer;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;

		@include media-breakpoint-up(sm) {
			min-height: 250px;
		}

		@include media-breakpoint-up(md) {
			//border: 1px solid green;
			width: calc(25% - $imageGridSpacer);
		}

		@include media-breakpoint-up(lg) {
			min-height: 350px;
		}
	}

	.image-grid-title{
		@include r-font-size(16, 22);
	}

}