
.display-1{
    //color: red!important;
    @include r-font-size(64, 100);
}

.display-2{
    //color: blue!important;
    @include r-font-size(56, 80);
}

.display-3{
    //color: green!important;
    @include r-font-size(40, 64);
}

.display-4{
    //color: red!important;
    @include r-font-size(32, 56);
}

.display-5{
    //color: blue!important;
    // font-size: calc(1.375rem + 1.5vw);
    // @include media-breakpoint-up(xl) {
    //     font-size: 3rem;
    // }
    @include r-font-size(28, 48);
}

.display-6{
    //color: green!important;
    // font-size: calc(1.425rem + 2.1vw);
    // @include media-breakpoint-up(xl) {
    //     font-size: 2.5rem;
    // }
    @include r-font-size(24, 32);
}