
@mixin infoBoxDownArrow($arrowBaseWid: 10px, $arrowBaseColor: white, $arrowBeforeWid: 8px, $arrowBeforeColor: red, $arrowAfterWid: 7px, $arrowAfterColor: blue) {
	position: relative;
    
    width: 0; 
    height: 0; 
	margin: 0 3px;
    border-left: $arrowBaseWid solid transparent;
    border-right: $arrowBaseWid solid transparent;
    border-top: $arrowBaseWid solid $arrowBaseColor;

	@if ($arrowAfterWid == 0) {

		//top: 0px;
		bottom: $arrowBaseWid * -1;
		left: calc(50% - 3px);
		transform: translateX(-50%);

		&::before{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowBeforeWid solid transparent;
			border-right: $arrowBeforeWid solid transparent;
			border-top: $arrowBeforeWid solid $arrowBeforeColor;
			z-index: 1;
			top: -12px;
			left: -1 * $arrowBeforeWid;
		}


	} @else {
		top: 1px;

		&::before{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowBeforeWid solid transparent;
			border-right: $arrowBeforeWid solid transparent;
			border-top: $arrowBeforeWid solid $arrowBeforeColor;
			z-index: 1;
			top: -11px;
			left: -1 * $arrowBeforeWid;
		}

		&::after{
			content: '';
			position: absolute;
			width: 0; 
			height: 0; 
			border-left: $arrowAfterWid solid transparent;
			border-right: $arrowAfterWid solid transparent;
			border-top: $arrowAfterWid solid $arrowAfterColor;
			z-index: 2;
			top: -13px;
			left: -1 * $arrowAfterWid;
		}
	}
}