.iframe-container {
	position: relative;
	max-width: 560px;
	aspect-ratio: 16 / 9;
	margin-bottom: 1rem;

	iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}    
}