
.form-control{
	&[type='radio']{
		width: 30px;
	}
}


//meant to mimic the look of the other placeholder text labels
.label-placeholder{
	font-family: $accentFont;
	font-weight: 700;
	color: $gray-500!important;
}


.validation-summary-errors{
	color: red;
}


//main container added inline by developer
.select2{
	&.select2-container {
		font-family: $accentFont;
		font-weight: 700;
		min-width: 100px;
		@include media-breakpoint-down(md) {
			width: 100% !important;
		}
		@include media-breakpoint-up(md) {
			max-width: 200px;
			width: auto !important;
		}
	}
	.select2-selection{
		min-height: 41px;
		border: 0 !important;
		font-family: $accentFont;
		font-weight: 700;
	}
}

//added by the code to the bottom of the page
.select2-container{
	.select2-results__options{
		font-family: $accentFont;
		font-weight: 700;
	}
}