//----------------------------------------------------------------------------//
// POSITION UTITLITIES - CENTERING ELEMENTS
//----------------------------------------------------------------------------//
.position-x-center {
    left: 50%;
    transform: translateX(-50%);
}

.position-y-center {
    top: 50%;
    transform: translateY(-50%);
}

.position-xy-center {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// Responsive
@if $enable-responsive-centering-elements==true {
    @each $breakpoint in map-keys($grid-breakpoints) {
        @if $breakpoint !=xs {
            @include media-breakpoint-up($breakpoint) {
                .position-x-#{$breakpoint}-center {
                    left: 50%;
                    transform: translateX(-50%);
                }

                .position-y-#{$breakpoint}-center {
                    top: 50%;
                    transform: translateY(-50%);
                }

                .position-xy-#{$breakpoint}-center {
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}

//----------------------------------------------------------------------------//
// POSITION UTITLITIES - SIDE OFFSETS
// - Creates T|R|B|L classes for all values in Bootstrap $spacers map
//----------------------------------------------------------------------------//
// Usage: 
// .top-{0-5} | .right-{0-5} | .bottom-{0-5} | .left-{0-5}
// .top-{bp}-{0-5} | .right-{bp}-{0-5} | .bottom-{bp}-{0-5} | .left-{bp}-{0-5}
// <div class="position-absolute top-1 left-1">
//----------------------------------------------------------------------------//
@each $side in $element-sides {

    @each $spacer,
    $value in $spacers {
        .#{$side}-#{$spacer} {
            #{$side}: $value;
        }

        // Responsive
        @if $enable-responsive-side-offsets==true {
            @each $breakpoint in map-keys($grid-breakpoints) {
                @if $breakpoint !=xs {
                    @include media-breakpoint-up($breakpoint) {
                        .#{$side}-#{$breakpoint}-#{$spacer} {
                            #{$side}: $value;
                        }
                    }
                }
            }
        }
    }
}