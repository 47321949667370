

.truncatedStory{
    h1,h2,h3,h4,h5,h6{
        color: $primary;
    }
    h2{
        @extend .display-4;
        &:not(:first-of-type){
            margin-top: 2rem;
        }
    }
    h3{
        @extend .display-5;

        &:not(:first-of-type){
            margin-top: 2rem;
        }
    }

    h4{
        @extend .display-6;

        &:not(:first-of-type){
            margin-top: 1.5rem;
        }
    }



    figure{
        margin: .5rem 0;
        width: 100%;
        text-align: center;
        img{
            margin: 0 auto;
            width: 100%;
            max-width: 800px;
        }
        figcaption{
            text-align: center;
            margin: .35rem 0 1rem;
            font-size: .875rem;
            color: $body-color;
        }
    }
}


