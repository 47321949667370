// body{
// 	border: 1px solid $red;
// 	background-color: unset;
// }

// h1,h2,h3,h4,h5,h6{
// 	font-family: $accentFont;
// }

.testjk{
	@include r-font-size(60, 100);
}

main{
	min-height: 400px;
}

.hidden{
	display: none;
}

.pe-none{
	pointer-events: none;
}

.bred{
    border: 1px solid red;
}
.bblu{
    border: 1px solid blue;
}
.bgrn{
    border: 1px solid green;
}

.z-1{
	z-index: 1;
}
.z-9{
	z-index: 9;
}
.z-999{
	z-index: 999;
}

.w-md-25{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 25%;
	} 
}
.w-md-33{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 33.33%;
	} 
}
.w-md-40{
	width: 100%;
	@include media-breakpoint-up(md) {
		//border: 1px solid green;
		width: 40%;
	} 
}
.w-md-50{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 50%;
	} 
}
.w-md-60{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 60%;
	} 
}
.w-md-66{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 66.66%;
	} 
}
.w-md-75{
	width: 100%;
	@include media-breakpoint-up(md) {
		width: 75%;
	} 
}

.mw-md-75{
	@include media-breakpoint-up(md) {
		max-width: 75%;
	}
}

.mw-200{
	max-width: 200px;
}
.mw-md-200{
	@include media-breakpoint-up(md) {
		max-width: 200px;
	}
}
.mw-300{
	max-width: 300px;
}
.mw-md-300{
	@include media-breakpoint-up(md) {
		max-width: 300px;
	}
}

$flexColSpacer: 1rem;
.flex-col-2{
	@include media-breakpoint-up(md) {
		.order-1{
			padding-right: $flexColSpacer;
		}
		.order-2{
			padding-left: $flexColSpacer;
		}
	}
}



.gradient-bottom{
	background: linear-gradient(transparent, rgba(0,0,0,.8));
}


$gridGutter: .75rem;
.directory-grid{
	margin-right: -1 * $gridGutter;
	.directory-grid-item{
		box-sizing: border-box;
		width: 48%;
		padding-right: $gridGutter;
	
		@include media-breakpoint-up(md) {
			width: 33.33%;
		}
	
		@include media-breakpoint-up(md) {
			width: 25%;
		}
	}
}


.transform-none{
	transform: unset!important;
}

.bg-quinary-ghosted{
	background-color: rgba($quinary, .5);
}


.hover-img-zoom{
	overflow: hidden;
	img{
		text-align:center;
		transition: all 0.15s;
		transition-timing-function: ease-out;
	}

	&:hover{
		img{
			transform: scale(1.1);
		}
	}
}



hr{
	&.dashed {
		border-top: 1px dashed $gray-300;

	}
}