.list-button-group{
	//border: 1px solid red;
	list-style-type: none;
	margin: 0;
	padding: 0;
	li{
		//border: 1px solid blue;
		display: block;
		padding: .35rem 1.25rem;
		margin: 0 0 .25rem;
		background-color: $gray-300;
		border-radius: 4px;
		a{
			font-weight: 600;

			.svg-inline--fa{
				margin-right: .5rem;
				transition: all 150ms linear;
			}

			&:hover{
				.svg-inline--fa{
					margin-right: 0;
				}
			}
		}
	}
}