

.infobox{
	cursor:pointer;

	
}

.infobox-details{
	top: 0;
	//left: 50%;
	//transform: translate(-50%, -100%);
	z-index: 9999;
	width: 300px;
	height: auto;
	transition: all 50ms ease-out;

	.infobox-details-close{
		top: 0;
		right: 0;
		width: 30px;
		height: 30px;
		cursor:pointer;
	}

	.infobox-details-arrow{
		@include infoBoxDownArrow(10px, white, 8px, white, 0);
	}
}