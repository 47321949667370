.datepicker-controls{
    button{
        border: unset;
    }
    .view-switch{
        font-family: $accentFont;
		font-weight: 700;
		font-size: 20px;
		color: $primary;
    }
}