
.label-bold,
select,
input{
    font-family: $accentFont;
    font-weight: 700!important;
    font-size: 1.25rem;

}


//uploader

.qq-upload-button{
    @extend .btn;
    @extend .btn-primary;
}


