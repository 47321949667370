.multiPinMap{
    //map won't load without dims!!
    width:100%;
    height:162px;
}


.map-destinations{
	//border: 1px solid red;
	height: 350px;
	@media screen and (min-width: $mq-tablet-portrait){
		//border: 1px solid green;
		height: 450px;
	}
	@media screen and (min-width:$mq-md){
		//border: 1px solid pink;
		height: 500px;
	}
	@media screen and (min-width:$mq-tablet-landscape){
		//border: 1px solid blue;
		height: 600px;
	}
}

.map-controls {
	position: absolute;
	display: inline-flex;
	top: 0;
	flex-direction: column;
	z-index: 3;
	.is-hidden-map & {
		display: none;
		@media screen and (min-width:$mq-tablet-landscape){
			display: block;
		}
	}
	.fixed & {
		//top: 50px;
	}
	@media screen and (min-width:$mq-tablet-landscape){
		right: 20px;
		flex-direction: row; 
		.fixed & {
			top: 150px;
		}
	}
}

.map-controls__button {
	border-radius: 50%;
	font-size: .9em;
	width: 45px;
	height: 45px; 
	text-align: center;
	img {
		display: inline-block;
		vertical-align: middle;
	}
}

.map-controls__button--reset {
	padding: 0;
	@media screen and (min-width:$mq-tablet-landscape){
		width: auto;
		border-radius: 1em;
		padding: 1px 6px;
	}
}

// .map-controls__button--pins {
// 	//has alignment issues if it's not inside this container
// 	padding: 0;
// 	.d-inline-block {
// 		&:before {
// 			content: url('/Images/conditions-reports/icons/vid-enable.svg');
// 			display: inline-block;
// 			vertical-align: middle;
// 			line-height: 0;
// 		}
// 	}
// 	&.is-active .d-inline-block:before {
// 		content: url('/Images/conditions-reports/icons/vid-disable.svg');
// 	}
// }

.map-controls__label {
	display: none;
	@media screen and (min-width:$mq-tablet-landscape){
		display: inline-block;
	}
}