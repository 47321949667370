.theme{
	//border: 1px solid blue;
	background-color: $theme-light-bg-color;
	color: $dark;
	h1,h2,h3,h4,h5,h6{
		color: $theme-light-header;//$primary;
	}

	.info{
		&-title{
			background-color: $theme-light-info-title;//white;
		}
	}

	&--dark{
		background-color: $theme-dark-bg-color;//$quinary;
		color: white;

		.info{
			&-title{
				background-color: $theme-dark-info-title;//$quinary;
			}
		}

		.list-icons{
			.svg-inline--fa{
				color: $theme-dark-list-icon-color;//rgb(172, 102, 102);
			}
		}

		
	}
}




.overhang-title{
	position: relative;
	margin-right: 0;
	@include media-breakpoint-up(md) {
		margin-right: -100px;

	}
	@include media-breakpoint-up(lg) {
		margin-right: -200px;
	}
	&::after{
		content: '';
		width: 15px;
		height: 100%;
		background-color: inherit;
		position: absolute;
		top: 0;
		right: -14px;
		bottom: 0;
		opacity: .5;
	}
}



.overhang-image-bottom{
	.media img{
		margin-bottom: 0;
		@include media-breakpoint-up(md) {
			margin-bottom: -150px;
		}
		@include media-breakpoint-up(lg) {
			margin-bottom: -100px;
		}
	}
	&::after{
		content: '';
		background-color: transparent;
		height: 0;
		display: block;
		position: relative;
		@include media-breakpoint-up(md) {
			height: 30px;
		}
		@include media-breakpoint-up(lg) {
			height: 50px;
		}
	}
}



$imageBorderWidth: 16px;
.image-border{
	box-sizing: border-box;
	margin-top: $imageBorderWidth;
	margin-left: $imageBorderWidth * -1;
	//margin-right: $imageBorderWidth * -1;
	box-shadow: inset 0px 0px 0px $imageBorderWidth white;
	padding: $imageBorderWidth;
	position: relative;

	&::before{
		content: '';
		background-color: rgba(white, .5);
		height: $imageBorderWidth;
		width: 100%;
		display: block;
		position: absolute;
		top: -1 * $imageBorderWidth;
		left: 0;
	}
}



$padBotSm: 50px;
$padBotMd: 90px;
$padBotLg: 130px;

.overhang-section-pad-bottom{
	padding-bottom: $padBotSm;
	@include media-breakpoint-up(md) {
		padding-bottom: $padBotMd;
	}
	@include media-breakpoint-up(lg) {
		padding-bottom: $padBotLg;
	}
}

.overhang-section-negative-top{
	margin-top: $padBotSm * -1;
	@include media-breakpoint-up(md) {
		margin-top: $padBotSm * -1;
	}
	@include media-breakpoint-up(lg) {
		margin-top: $padBotLg * -1;
	}

	// &--xtra{
	// 	@extend .overhang-section-negative-top;
	// 	@include media-breakpoint-up(md) {
	// 		margin-top: $padBotSm * -1.25;
	// 	}
	// 	@include media-breakpoint-up(lg) {
	// 		margin-top: $padBotLg * -1.25;
	// 	}
	// 	@include media-breakpoint-up(lg) {
	// 		margin-top: $padBotLg * -1.25;
	// 	}
	// }
}