$bar-height: 3px;
$bar-spacing: 8px;

.dropdown-menu{
    top: -10px!important;
    @include media-breakpoint-up(md) {
        top: 0!important;
    }
}

.nav-link{
    font-size: 1rem;
    padding: $nav-link-padding-y $nav-link-padding-x;
    @include media-breakpoint-up(lg) {
        padding: ($nav-link-padding-y * .55) ($nav-link-padding-x * .55);
    }
    @include media-breakpoint-up(xl) {
        padding: $nav-link-padding-y $nav-link-padding-x;
    }
}

.hamburger-menu {
    width: 100%;
    height: $bar-height + $bar-spacing*2;

    &-container {
        position: relative;
        width: 25px;
        z-index: 600;
    }
    
    .hamburger-bars {
        display: block;
        position: relative;
        width: 100%;
        height: $bar-height;
        background: $primary;
        transform: translateY($bar-spacing);
        transition: all 0ms 300ms;
    
        &:before, &:after {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: $bar-height;
            background: $primary;
        }
    
        &:before {
            bottom: $bar-spacing;
            transition: bottom 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    
        &:after {
            top: $bar-spacing;
            transition: top 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms cubic-bezier(0.23, 1, 0.32, 1);
        }
    }
    
    &.animate {
        .hamburger-bars {
            background: rgba(255, 255, 255, 0);
            
            &:after {
                top: 0;
                background-color: $primary;
                transform: rotate(45deg);
                transition: top 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
            }
    
            &:before {
                bottom: 0;
                background-color: $primary;
                transform: rotate(-45deg);
                transition: bottom 300ms cubic-bezier(0.23, 1, 0.32, 1), transform 300ms 300ms cubic-bezier(0.23, 1, 0.32, 1);
            }
        }
    }
}


$offcanvasNavWidth: 300px;
.offcanvas-nav {
    position: fixed;
    top: 0;
    right: $offcanvasNavWidth * -1;
    bottom: 0;
    width: $offcanvasNavWidth;
    padding: 1.85rem .25rem 1rem;
    overflow: hidden;
    transition: all 0.26s ease-in-out;
    z-index: 500;

    @include media-breakpoint-up(sm) {
        right: $offcanvasNavWidth * -1;
        width: $offcanvasNavWidth;
    }

    &.active {
        width: 85%;
        right: 0;
        transition: all 0.26s ease-in-out;
        box-shadow: -2px .125rem .25rem rgba($black,.35) !important;

        @include media-breakpoint-up(sm) {
            width: $offcanvasNavWidth;
        }
    }

    &-shadow{
        background-color: black;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 400;
        width: 100%;
        opacity: .01;
        display: none;
        transition: all 0.6s ease-in-out;

        &.active {
            display: block;
            opacity: .5;
        }
    }
}



$searchHeight: 92px;
.offcanvas-search {
    position: fixed;
    display: flex;
    align-items: center;
    top: $searchHeight * -1;
    left: 0;
    right: 0;
    width: 100%;
    height: $searchHeight;
    padding: auto 1rem auto;
    overflow: auto;
    background-color: white;
    transition: all 0.26s ease-in-out;
    z-index: 604;
    opacity: 0;

    &.active {
        opacity: 1;
        top: 0;
        transition: all 0.26s ease-in-out;
        box-shadow: -2px .125rem .25rem rgba($black,.35) !important;
    }
}