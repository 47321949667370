.spinner{
	//inspired by https://codepen.io/smashtheshell/pen/jqGxzr
	width: 80px;
	height: 80px;
	
	border: 7px solid rgba($primary,.5);
	border-top: 7px solid $primary;
	border-radius: 100%;
	
	position: absolute;
	top:0;
	bottom:0;
	left:0;
	right: 0;
	margin: auto;
	
	animation: spin 1s infinite linear;
}

.spinner--small {
	width: 80px;
	height: 80px;
	border: 5px solid #f7f7f7;
	border-top: 6px solid $primary;
}

@keyframes spin {
	from{
			transform: rotate(0deg);
	}to{
			transform: rotate(360deg);
	}
}