

.card-hover{
    .card-img-container{
        overflow: hidden;
        img{
            text-align:center;
            transition: all 0.15s;
            transition-timing-function: ease-out;
        }
    }
    &:hover {
        .card-img-container{
            img{
                transform: scale(1.1);
            }
        }
    }
}

.card{
    overflow: hidden;

    .featured{
        position: absolute;
        top: .5rem;
        left: 0;
        width: auto;
        display: flex;
        align-items: center;
        z-index: 9;
        background-color: $senary;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        padding: .25rem .5rem;
        font-family: $accentFont;
        font-weight: bold;
        @include r-font-size(14,16);
        color: $primary;
    }
}