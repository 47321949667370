.pagingContainer{
    //border: 1px solid red;
    display: flex;
    list-style-type: none;

    li{
        //border: 1px solid blue;
        background-color: $gray-400;
        margin-right: .25rem;
        font-family: $accentFont;
        font-weight: 700;
        border-radius: .25rem;
        display: flex;
        flex-direction: column;
        justify-content: center;

        a{
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: .25rem 1.0rem;
        }

        &.carousel-active-tab{
            background-color: $primary;
            
            a{
                color: white;
            }
        }
    }
}